export enum StatusHttp {
    Success = 200,
    ServerError = 500
}

export enum AppStorageKey {
    AuthTokenObject = 'paco.app.token',
    OneSignalUserToken = 'onesignal.user.token',
    IsOpenFromNotification = 'onesignal.isOpenFromNotification.token',
    HasDebtsToken = 'debts.token'
}